import React from 'react'
import styled from 'styled-components'

import { Layout } from '../components/layout'

import { Footer } from '../components/footer'
import { Teaser } from '../components/teaser'

import { Image } from '../components/common/image'
import { Grid, Row, Col } from '../components/common/grid'
import { Space } from '../components/common/space'
import { Flex } from '../components/common/flex'
import { H4 } from '../components/common/heading'

const List = styled.ul`
  margin: 0;
`

const ListItem = styled.li`
  color: #69707b;
  text-align: justify;
  margin-bottom: 10px;
`

const title = 'Chimpanzee Natural Nutrition'
const description =
  'Albedo ist der offizielle Vertriebspartner für Chimpanzee Natural Nutrition.'
const keywords = ''

const IndexPage = () => (
  <Layout title={title} description={description} keywords={keywords}>
    <Teaser title={title} description={description} imageName="quick_mix" />

    <Grid>
      <Row centered>
        <Col xs={12} md={9}>
          <div>
            <p>
              Wir verfolgen ein nachhaltiges, ökonomisches Geschäftsmodell. In
              unserer Produktion verwenden wir nur natürliche und qualitativ
              hochwertige Inhaltsstoffe. Wir unterstützen die regionale
              Landwirtschaft und versuchen den ökologischen Fußabdruck in all
              unseren Prozessen zu minimieren.
            </p>

            <Space top={50} bottom={50}>
              <Flex>
                <Image name="logo_for_the_planet" />
                <Image name="logo_vegetarian_society" />
                <Image name="logo_vegan" />
              </Flex>
            </Space>

            <p>
              Wir produzieren rein natürliche Energie- und Proteinriegel,
              Gunpowder Energy Drinks, Shakes, Gels und Frucht-Gummibärchen.
              Vegetarische und vegane Inhaltsstoffe bieten die Basis für unsere
              außergewöhnlichen Geschmackskompositionen. Unsere liebevoll
              gestalteten Produkte richten sich nicht nur an
              Hochleistungssportler sondern sind ebenso gesunde
              Energielieferanten für den Alltag. Eine gesunde Ernährung und sehr
              hohe Qualitätsstandards bilden den Kern unserer Marke und
              durchziehen alle unsere Aktivitäten. Von der Auswahl der Zutaten
              bis hin zur unverwechselbaren Aufmachung der Produkte, investieren
              wir viel Zeit und achten auf jedes Detail.
            </p>

            <Space top={50} bottom={20}>
              <H4>Veganes und vegetarisches Sortiment</H4>
            </Space>

            <List>
              <ListItem>
                100% natürliche Inhaltsstoffe (ohne Geschmacksverstärker,
                Gluten, Transfette oder GMOs)
              </ListItem>
              <ListItem>
                Natürliche Energie- und Proteinquelle vor, während und nach
                sportlicher Belastung - oder einfach als Snack für
                zwischendurch!
              </ListItem>
              <ListItem>Ausschließlich Fair Trade Schokolade</ListItem>
              <ListItem>
                Unvergessliches Geschmackserlebnis (Chocolate Espresso, Cashew
                Caramel, Apple Ginger und viele mehr ...)
              </ListItem>
            </List>
          </div>
        </Col>
      </Row>
    </Grid>

    <Footer />
  </Layout>
)

export default IndexPage
